import Hero from 'features/Hero'
import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import CtaWidgets from 'features/CtaWidgets'
import { Flatten, PickFirst } from 'services/utils'
import Resources from 'features/Resources'
import SupportingContentBlocks from 'features/SupportingContentBlocks'
import CardContainer from 'layouts/CardContainer'

const ServiceSuiteContents = ({ data = {} }) => {
  const {
    name,
    width,
    taglineOrSubhead,
    supportingContent = [],
    solutions = [],
    supportingContent2 = [],
    resources = [],
    footerCTA,
    heroCTA = []
  } = data

  const solutionCards = solutions.map((solution) => {
    const solutionDetail = PickFirst(solution.solution) || {}
    const pageBase = solutionDetail ? PickFirst(solutionDetail.pageBase) : {}
    const icon = pageBase ? PickFirst(pageBase.icon) : {}
    const children = solution.childSolutions?.map((child) => {
      child = Flatten(child)
      return {
        name: child.name,
        url: PickFirst(child.pageBase)?.url || '#'
      }
    })
    return {
      headline: solution.headline,
      subheadline: solution.subheadline,
      icon: icon.icon,
      iconColor: icon.color,
      type: 'icon',
      targetPage: pageBase,
      children: children?.length > 0 ? children : null,
      hasChildren: children?.length > 0
    }
  })

  return (
    <>
      <Hero
        data={{
          headline: name,
          subheadline: taglineOrSubhead,
          width: width && width.length > 0 ? PickFirst(width).shortName : 'normal'
        }}
      />
      {heroCTA && 
      <div id="marketoForm">
      <CtaWidgets className="mt-5 text-center cta-widgets" ctaWidgets={heroCTA} />
      </div>
      }
      <Spacer size={20} mdSize={13} />

      <SupportingContentBlocks blocks={supportingContent} />

      <CardContainer
        className="mb-20 md-mb-13"
        data={{
          cards: solutionCards || []
        }}
      />

      <SupportingContentBlocks blocks={supportingContent2} />

      <Resources resources={resources} className="mb-20 md-mb-13" />

      <CtaBanner data={PickFirst(footerCTA)} />
    </>
  )
}
export default ServiceSuiteContents
