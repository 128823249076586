import {
  TestimonialContentFeatureFragment,
  CardsContentFeatureFragment,
  CalltoactionFragment,
  ContentFeatureContentBlocksFragment,
  SupportingContent,
  StatFeatureFragment,
  ImageGridFragment,
  HeadlineFragment,
  ContentFeaturePeopleFragment,
  PageBaseFragment,
  TombstoneFeatureFragment
} from './fragments'

export const ResourceCardsQuery = (currentId, type) => {
  const filters = []
  let filterString = ''
  if (currentId) {
    filters.push(`id ne '${currentId}'`)
  }
  if (type) {
    filters.push(`data/type/iv eq '${type}'`)
  }
  if (filters.length > 0) {
    filterString = `, filter: "${filters.join(' and ')}"`
  }
  return `query {
  queryResourceContents(top:3, orderby: "data/createdAt/iv desc" ${filterString}){
    flatData{
      title
      shortDescription
      cardMapEmbed
      type{
        flatData{
          name
        }
      }
      pageBase{
        flatData{
          url
        }
      }
      mainImage{
        id
        slug
        fileName
        metadata
      }
      featuredImage{
        id
        slug
        fileName
        metadata
      }
      categories{
        flatData{
          name
          slug
        }
      }
      topics{
        flatData{
          name
          slug
        }
      }
      serviceSuites{
        id
        flatData{
          name
        }
      }
      solutions{
        id
        flatData{
          name
        }
      }
    }
  }
}

`
}

export const ResourceCardsAllQuery = (skip = 0) => `
    query{
      queryResourceContentsWithTotal(top: 200, skip: ${skip}) {
        total
        items {
          flatData{
            title
            shortDescription
            featured
            createdAt
            cardMapEmbed
            pageBase{
              flatData{
                url
              }
            }
            author{
              flatData{
                firstName
                lastName
              }
            }
            type{
              flatData{
                name
              }
            }
            mainImage{
              id
              slug
              fileName
              metadata
            }
            featuredImage{
              id
              slug
              fileName
              metadata
            }
            categories{
              flatData{
                name
                slug
              }
            }
            topics{
              flatData{
                name
                slug
              }
            }
            serviceSuites{
              id
              flatData{
                name
              }
            }
            solutions{
              id
              flatData{
                name
              }
            }
          }
        }
      }
    }
`

export const PodcastCardsAllQuery = `query {
  queryResourceContents(top:200, filter:"data/type/iv eq 'ae653360-5562-465d-b658-4cd0f7a0b42d'"){
    flatData{
      title
      shortDescription
      featured
      createdAt
      cardMapEmbed
      pageBase{
        flatData{
          url
        }
      }
      author{
        flatData{
          firstName
          lastName
        }
      }
      type{
        flatData{
          name
        }
      }
      mainImage{
        id
        slug
        fileName
        metadata
      }
      featuredImage{
        id
        slug
        fileName
        metadata
      }
      categories{
        flatData{
          name
          slug
        }
      }
      topics{
        flatData{
          name
          slug
        }
      }
      serviceSuites{
        id
        flatData{
          name
        }
      }
      solutions{
        id
        flatData{
          name
        }
      }
    }
  }
}
`
export const VideoCardsAllQuery = `query {
  queryResourceContents(top:200, filter:"data/type/iv eq 'ee08176e-1cc8-43f1-857d-fdbf17986609'"){
    flatData{
      title
      shortDescription
      featured
      createdAt
      cardMapEmbed
      pageBase{
        flatData{
          url
        }
      }
      author{
        flatData{
          firstName
          lastName
        }
      }
      type{
        flatData{
          name
        }
      }
      mainImage{
        id
        slug
        fileName
        metadata
      }
      featuredImage{
        id
        slug
        fileName
        metadata
      }
      categories{
        flatData{
          name
          slug
        }
      }
      topics{
        flatData{
          name
          slug
        }
      }
      serviceSuites{
        id
        flatData{
          name
        }
      }
      solutions{
        id
        flatData{
          name
        }
      }
    }
  }
}
`
export const CalculatorsCardsAllQuery = `query {
  queryResourceContents(top:200, filter:"data/type/iv eq '96cca282-5931-4bc9-bd84-3244daaa2720'"){
    flatData{
      title
      shortDescription
      taglineOrSubhead
      calculatorCode
      divTag
      featured
      createdAt
      cardMapEmbed
      pageBase{
        flatData{
          url
        }
      }
      author{
        flatData{
          firstName
          lastName
        }
      }
      type{
        flatData{
          name
        }
      }
      mainImage{
        id
        slug
        fileName
        metadata
      }
      featuredImage{
        id
        slug
        fileName
        metadata
      }
      categories{
        flatData{
          name
          slug
        }
      }
      topics{
        flatData{
          name
          slug
        }
      }
      serviceSuites{
        id
        flatData{
          name
        }
      }
      solutions{
        id
        flatData{
          name
        }
      }
    }
  }
}
`

export const LandingPageQuery = (identifier) => `query {
  queryLandingPagesContents(filter: "data/identifier/iv eq '${identifier}'"){
    id
    flatData{
      headline
      subheadline
      noEventsMessage
      featuredContent{
        __typename
        ... on LandingPages{
          flatData{
            headline
            featuredImage{
              fileName
              metadata
            }
            pageBase{
              flatData{
                url
              }
            }
          }
        }
        ... on Resource{
          flatData{
            title
            featuredImage{
              fileName
              metadata
            }
            mainImage{
              fileName
              metadata
            }
            pageBase{
              flatData{
                url
              }
            }
          }
        }
        ... on InteriorBlockPage{
          flatData{
            hero{
              flatData{
                headline
              }
            }
            featuredImage{
              fileName
              metadata
            }
            pageBase{
              flatData{
                url
              }
            }
          }
        }
      }
      supportingContent{
        ${SupportingContent}
      }
      supportingContent2{
        ${SupportingContent}
      }
      footerCTA{
        ...CalltoactionFragment
      }
      pageBase{
        flatData{
          ${PageBaseFragment}
          pageBackground{
            color{
              flatData{
                shortName
              }
            }
            image {
              flatData{
                imageName
              }
            }
            alignment{
              flatData{
                shortName
              }
            }
          }
        }
      }
    }
  }
}
${TestimonialContentFeatureFragment}
${CardsContentFeatureFragment}
${CalltoactionFragment}
${ContentFeatureContentBlocksFragment}
${StatFeatureFragment}
${ImageGridFragment}
${HeadlineFragment}
${ContentFeaturePeopleFragment}
${TombstoneFeatureFragment}
`
export const ResourceDetail = `

`
