import { GQLClient } from './graphql'
import { ResourceCardsQuery } from 'queries/resources'
import { Flatten, PickFirst } from './utils'
import { ResourceCardFragment } from 'queries/fragments'
export const HasResources = (pageData) => {
  return (
    Object.keys(pageData).includes('relatedResources') ||
    Object.keys(pageData).includes('resources')
  )
}

export const HandleResources = async ({ pageData, force = false, pageId }) => {
  const keys = Object.keys(pageData)
  if (keys.includes('resourcesWithHeadline') || force === true) {
    pageData.resources = PickFirst(pageData.resourcesWithHeadline) || {}
    const isShort = force ? 3 : 3 - pageData.resourcesWithHeadline?.resource?.length
    if (isShort === 0) {
      return pageData
    }

    const client = await GQLClient()
    const data = await client
      .request(
        ResourceCardsQuery(
          pageData.id || '',
          pageData?.type ? PickFirst(pageData?.type || [])?.id || '' : ''
        ),
        {}
      )
      .catch((err) => console.log(err))
    if (!pageData.resources.resource) {
      pageData.resources.resource = []
    }
    data.queryResourceContents.forEach((resource) => {
      if (pageData.resources.resource.length < 3) {
        pageData.resources.resource.push(Flatten(resource))
      }
    })
  }

  return pageData
}

export const Filters = {
  topic: {
    query: (slug) => {
      return `
      query{
        queryContentTopicContents(filter: "data/slug/iv eq '${slug}'", top: 200){
          referencingResourceContents{
            ${ResourceCardFragment}
          }
        }
      }
    `
    },
    process: (data) => {
      if (data.queryContentTopicContents.length === 0) {
        return []
      }
      const resources = data.queryContentTopicContents[0]
      return {
        resource: resources.referencingResourceContents
          .map((resource) => Flatten(resource))
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
      }
    }
  },
  category: {
    query: (slug) => {
      return `
      query{
        queryContentCategoryContents(filter: "data/slug/iv eq '${slug}'", top: 200){
          referencingResourceContents{
            ${ResourceCardFragment}
          }
        }
      }
    `
    },
    process: (data) => {
      if (data.queryContentCategoryContents.length === 0) {
        return []
      }
      const resources = data.queryContentCategoryContents[0]
      return {
        resource: resources.referencingResourceContents
          .map((resource) => Flatten(resource))
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
      }
    }
  },
  author: {
    query: (slug) => {
      return `
      query{
        queryPersonContents(filter: "id eq '${slug}'", top: 200){
          referencingResourceContents{
            ${ResourceCardFragment}
          }
        }
      }
    `
    },
    process: (data) => {
      if (data.queryPersonContents.length === 0) {
        return []
      }
      const resources = data.queryPersonContents[0]
      return {
        resource: resources.referencingResourceContents
          .map((resource) => Flatten(resource))
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
      }
    }
  },
  'service-suite': {
    query: (slug) => {
      return `
      query{
        queryServiceSuiteContents(filter: "id eq '${slug}'", top: 200){
          referencingResourceContents{
            ${ResourceCardFragment}
          }
        }
      }
    `
    },
    process: (data) => {
      if (data.queryServiceSuiteContents.length === 0) {
        return []
      }
      const resources = data.queryServiceSuiteContents[0]
      return {
        resource: resources.referencingResourceContents
          .map((resource) => Flatten(resource))
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
      }
    }
  },
  solution: {
    query: (slug) => {
      return `
      query{
        querySolutionContents(filter: "id eq '${slug}'", top: 200){
          referencingResourceContents{
            ${ResourceCardFragment}
          }
        }
      }
    `
    },
    process: (data) => {
      if (data.querySolutionContents.length === 0) {
        return []
      }
      const resources = data.querySolutionContents[0]
      return {
        resource: resources.referencingResourceContents
          .map((resource) => Flatten(resource))
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
      }
    }
  }
}
