import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import CtaWidgets from 'features/CtaWidgets'
import Hero from 'features/Hero'
import Resources from 'features/Resources'
import SupportingContentBlocks from 'features/SupportingContentBlocks'
import { HasBoardLinks, PickFirst } from 'services/utils'
import { useEffect } from 'react'
import useMarketo from 'hooks/useMarketo'
import styles from 'styles/Contact.module.scss'
import Container from 'containers/Container'

const InteriorBlockPageContents = ({ data = {} }) => {
  const { 
    hero = [], 
    supportingContent = [], 
    showResources, 
    resources = [], 
    footerCTA, 
    heroCTA = [],
    trackingCode,
    featuredImage,
    marketoid,
    thankYouPage
  } = data

  const { onLoadForm, onRemoveForm, formLoaded, formSuccess } = useMarketo({
    munchkinId: '880-QNO-957',
    formId: marketoid,
    callback: () => {
      // location.hash = 'hours-anchor'
    }
  })

  useEffect(() => {
    if (!formLoaded && marketoid) {
      onLoadForm()
    }
    if(formSuccess && thankYouPage){
      const thankYouPageUrl = thankYouPage[0].pageBase[0].url
      router.push(`/${thankYouPageUrl}`)
    }
  }, [formLoaded, onLoadForm, formSuccess, marketoid])
  
  return (
    <>
      <Hero 
        data={hero[0] || {}}         
        backgroundImage={featuredImage ? featuredImage[0] : {}}
      />

      {heroCTA && 
      <div id="marketoForm">
        <CtaWidgets className="mt-5 text-center cta-widgets" ctaWidgets={heroCTA} />
      </div>
      }
      <Spacer size={HasBoardLinks ? 10 : 20} mdSize={13} />

      {formSuccess && !thankYouPage && (
        <Container narrow={true} >
          <div id="success-message" className="intro-paragraph font-weight-bold">
            Thank you for taking the time to connect with First Business Bank. A First Business Bank
            representative will follow-up with you soon.
          </div>
          <Spacer size={8} />
        </Container>
      )}
      {!formSuccess && (
        <Container narrow={true} >
          <form className={styles['form']} id={`mktoForm_${marketoid}`}></form>
          <Spacer size={8} />
        </Container>
      )}

      <SupportingContentBlocks blocks={supportingContent} />

      {showResources && <Resources resources={resources} className="mb-20 md-mb-13" />}

      <CtaBanner data={PickFirst(footerCTA)} />

      <div
        className="tracking-code"
        dangerouslySetInnerHTML={{ __html: trackingCode }}
      />

    </>
  )
}

export default InteriorBlockPageContents
