import Hero from 'features/Hero'
import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import { PickFirst } from 'services/utils'
import Resources from 'features/Resources'
import SupportingContentBlocks from 'features/SupportingContentBlocks'

const ProductContents = ({ data = {}, breadcrumbs = [], pathData = {} }) => {
  const {
    name,
    width,
    taglineOrSubhead,
    supportingContent = [],
    supportingContent2 = [],
    resources = [],
    footerCTA
  } = data

  return (
    <>
      <Hero
        data={{
          headline: name,
          subheadline: taglineOrSubhead,
          width: width && width.length > 0 ? PickFirst(width).shortName : 'normal'
        }}
        breadcrumbs={breadcrumbs}
        pathData={pathData}
      />

      <Spacer size={20} mdSize={13} />

      <SupportingContentBlocks blocks={supportingContent} />

      <SupportingContentBlocks blocks={supportingContent2} />

      <Resources resources={resources} className="mb-20 md-mb-13" />

      {footerCTA && footerCTA.length > 0 && <CtaBanner data={PickFirst(footerCTA)} />}
    </>
  )
}
export default ProductContents
