import Hero from 'features/Hero'
import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import { PickFirst } from 'services/utils'
import CardContainer from 'layouts/CardContainer'
import Resources from 'features/Resources'
import SupportingContentBlocks from 'features/SupportingContentBlocks'

const ProductGroupContents = ({ data = {}, breadcrumbs = [], pathData = {} }) => {
  const {
    name,
    taglineOrSubhead,
    supportingContent = [],
    supportingContent2 = [],
    associatedSolutionsOrServiceSuites = [],
    resources = [],
    footerCTA
  } = data

  const associatedSolutionsOrServiceSuitesCards = associatedSolutionsOrServiceSuites.map(
    (solution) => {
      const pageBase = PickFirst(solution.pageBase) || {}
      const icon = PickFirst(pageBase.icon) || {}
      return {
        headline: solution.name,
        subheadline: solution.shortDescription,
        icon: icon.icon,
        iconColor: icon.color,
        type: 'icon',
        targetPage: pageBase
      }
    }
  )

  return (
    <>
      <Hero
        data={{
          headline: name,
          subheadline: taglineOrSubhead
        }}
        breadcrumbs={breadcrumbs}
        pathData={pathData}
      />

      <Spacer size={20} mdSize={13} />

      <SupportingContentBlocks blocks={supportingContent} />

      <CardContainer
        className="mb-20 md-mb-13"
        data={{
          headline: `${name} Services`,
          subheadline: 'Leverage our experience and your assets to propel your business forward.',
          cards: associatedSolutionsOrServiceSuitesCards || [],
          cta: []
        }}
      />

      <SupportingContentBlocks blocks={supportingContent2} />

      <Resources
        resources={resources}
        headline={`Stay Up-To-Date On ${name}`}
        subheadline={
          'Find out how our industry experts are helping companies leverage speciality finance services to grow business.'
        }
        className="mb-20 md-mb-13"
      />

      {footerCTA && footerCTA.length > 0 && <CtaBanner data={PickFirst(footerCTA)} />}
    </>
  )
}
export default ProductGroupContents
