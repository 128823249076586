import Hero from 'features/Hero'
import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import CtaWidgets from 'features/CtaWidgets'
import { PickFirst } from 'services/utils'
import CardContainer from 'layouts/CardContainer'
import Resources from 'features/Resources'
import SupportingContentBlocks from 'features/SupportingContentBlocks'

const SolutionContents = ({ data = {}, breadcrumbs = [], pathData = {} }) => {
  const {
    name,
    width,
    taglineOrSubhead,
    supportingContent = [],
    supportingContent2 = [],
    associatedSolutionsOrServiceSuites = [],
    resources = [],
    footerCTA,
    heroCTA = []
  } = data

  const associatedSolutionsOrServiceSuitesCards = associatedSolutionsOrServiceSuites.map(
    (solution) => {
      const pageBase = PickFirst(solution.pageBase) || {}
      const icon = PickFirst(pageBase.icon) || {}
      return {
        headline: solution.name,
        subheadline: solution.shortDescription,
        icon: icon.icon,
        iconColor: icon.color,
        type: 'icon',
        targetPage: pageBase
      }
    }
  )

  return (
    <>
      <Hero
        data={{
          headline: name,
          subheadline: taglineOrSubhead,
          width: width && width.length > 0 ? PickFirst(width).shortName : 'normal'
        }}
        breadcrumbs={breadcrumbs}
        pathData={pathData}
      />
      {heroCTA && 
      <div id="marketoForm">
      <CtaWidgets className="mt-5 text-center cta-widgets" ctaWidgets={heroCTA} />
      </div>
      }

      <Spacer size={20} mdSize={13} />

      <SupportingContentBlocks blocks={supportingContent} />

      {associatedSolutionsOrServiceSuitesCards &&
        associatedSolutionsOrServiceSuitesCards.length > 0 && (
          <CardContainer
            className="mb-20 md-mb-13"
            data={{
              cards: associatedSolutionsOrServiceSuitesCards || [],
              cta: []
            }}
          />
        )}

      <SupportingContentBlocks blocks={supportingContent2} />

      <Resources resources={resources} className="mb-20 md-mb-13" />

      {footerCTA && footerCTA.length > 0 && <CtaBanner data={PickFirst(footerCTA)} />}
    </>
  )
}
export default SolutionContents
