import { Flatten, PickFirst, CamelCase } from 'services/utils'
import { HasResources, HandleResources } from 'services/resources.service'

import PageLayout from 'layouts/PageLayout'

import Masthead from 'features/Masthead'
import Footer from 'features/Footer'

import InteriorBlockPageContents from 'templates/InteriorBlockPageContents'
import ServiceSuiteContents from 'templates/ServiceSuiteContents'
import SolutionContents from 'templates/SolutionContents'
import { useRouter } from 'next/router'
import { IgnoreRoutes } from 'constants/config'
import { GetCachedData, GetMetaData } from 'services/data.service'

import { GQLClient } from 'services/graphql'
import { AllStaticPaths, SearchForPath } from 'queries/static-paths'
import Button from 'basics/Button/Button'
import ProductContents from 'templates/ProductContents'
import ProductGroupContents from 'templates/ProductGroupContents'
import LandingPagesContents from 'templates/LandingPagesContents'

const Config = {
  pathsFile: 'data/paths.json'
}

const Templates = {
  interiorBlockPageContents: InteriorBlockPageContents,
  serviceSuiteContents: ServiceSuiteContents,
  solutionContents: SolutionContents,
  productContents: ProductContents,
  productGroupContents: ProductGroupContents,
  landingPagesContents: LandingPagesContents
}

function Interior({
  pageData,
  type,
  topNav,
  mainNav,
  meta,
  pathData,
  footerContent,
  applicationSettings = {}
}) {
  const router = useRouter()
  if (router.isFallback || !pageData) {
    return (
      <div className="text-align-center">
        <h1>Page regenerating, reload in a second</h1>
        <Button onClick={() => location.reload(0)}>Refresh</Button>
      </div>
    )
  }

  const Component = Templates[type || 'interiorBlockPageContents']
  let headerFooterTemplate = 'normal'
  if (type === 'interiorBlockPageContents' && pageData.template && pageData.template.length > 0) {
    headerFooterTemplate = pageData.template[0].templateName || 'normal'
  }

  return (
    <PageLayout meta={meta} applicationSettings={applicationSettings || {}}>
      <Masthead
        type={headerFooterTemplate}
        topLinks={topNav?.links || []}
        mainLinks={mainNav?.links || []}
        route={router.query || {}}
      />
      <Component data={pageData} breadcrumbs={router.query || {}} pathData={pathData} />
      <Footer
        type={headerFooterTemplate}
        navLinks={topNav?.links || []}
        footerLinks={mainNav?.links || []}
        footerContent={footerContent}
      />
    </PageLayout>
  )
}

export default Interior

export async function getStaticProps(context) {
  const searchSlug = (context.params?.slug || []).join('/')
  const client = await GQLClient()
  const data = await client.request(SearchForPath(searchSlug))
  const content = PickFirst(data.queryBasePageContents)
  const cache = (await import('data/cache.json')).default
  const basePages = cache.queryBasePageContents
  const pathMap = {}
  basePages.forEach((page) => {
    page = Flatten(page)
    if (page && (page.urlCanonical || page.url)) {
      pathMap[(page.urlCanonical || page.url.split('/').pop()).trim()] = page.name
    }
  })
  let pageData = {
    type: '',
    pageData: {},
    meta: GetMetaData(content),
    pathData: pathMap
  }

  if (content) {
    Object.entries(content).some(([key, data]) => {
      if (Array.isArray(data) && data.length > 0) {
        pageData.type = CamelCase(key.replace('referencing', ''))
        pageData.pageData = Flatten(PickFirst(data) || {})
        return true
      }
    })
  }

  pageData.pageData = await HandleResources({ pageData: pageData.pageData })
  pageData = GetCachedData(pageData)
  const config = {
    props: pageData || {}
  }

  if (process.env.IS_PREVIEW) {
    config.revalidate = 2
  }

  return config
}

export async function getStaticPaths() {
  const client = await GQLClient()
  const data = await client.request(AllStaticPaths, {}).catch((err) => console.log(err))
  const basePages = []
  Object.entries(data).forEach(([key, value]) => {
    value?.forEach((val) => {
      const record = PickFirst(Flatten(val)?.pageBase) || {}
      basePages.push(record)
    })
  })
  // const basePages = data.queryBasePageContents
  const paths = basePages
    .filter((page) => page.url && page.url !== '/' && !IgnoreRoutes.has(page.url.split('/')[0]))
    .map((page) => {
      return {
        params: { slug: page.url !== '/' ? page.url.split('/') : [] }
      }
    })
  return {
    paths,
    fallback: false
  }
}
